<template>
  <div v-shortkey="shortKeys" @shortkey="shortKeyAction" ref="fullscreenElement" class="quiz-section p-4">
    <div v-if="showExitConfirmation">
      <ExitConfirmationSession :showExitConfirmation="showExitConfirmation"
        :closeExitConfirmationSession="closeExitConfirmationSession"
        :confirmeExitConfirmationSession="confirmeExitConfirmationSession" :isEndSession="isEndSession"
        :NoteSession="NoteSession" />
    </div>
    <div v-if="pauseSessionModal">
      <PauseSession :pauseSessionModal="pauseSessionModal" :closePauseSession="closePauseSession" />
    </div>
    <div v-if="reportQuestionSessionModal">
      <ReportQuestionSession :reportQuestionSessionModal="reportQuestionSessionModal"
        :closeReportQuestion="closeReportQuestion" :selectedIdType="selectedIdType" />
    </div>

    <div v-if="addToPlaylistModal">
      <AddToPlayList :addToPlaylistModal="addToPlaylistModal" :closeAddToPlaylist="closeAddToPlaylist"
        :selectedIdType="selectedIdType" />
    </div>

    <div v-if="deleteQuestionFromPlaylistDialogVisible">
      <DeleteQuestionFromPlaylist :deleteQuestionFromPlaylistDialogVisible="deleteQuestionFromPlaylistDialogVisible"
        :closeDeleteQuestionFromPlaylistModal="closeDeleteQuestionFromPlaylistModal"
        :closeDeleteQuestionFromPlaylistModalWithdelete="closeDeleteQuestionFromPlaylistModalWithdelete"
        :selectedIdType="selectedIdType" :idSession="idSession" />
    </div>


    <div :class="pauseSessionModal ? 'hidden' : 'block'"
      class="sm:px-2 lg:px-2 xl:px-2 sm:pt-2 pb-0 sm:pb-2 lg:pb-2 xl:pb-2 sm:space-y-2 h-full">

      <div class="flex h-full w-full" :class="isLoading ? 'hidden' : 'block'">
        <div class="flex w-full flex-col h-full gap-4">
          <div class="quiz-section__headline">
            <div class="w-full flex flex-col mb-2">
              <!-- <div class="mb-2">
                    <p :class="dark ? 'text-white':'text-gray-800'" class="font-semibold text-sm text-left ml-2 sm:block hidden">Questions</p>
                </div> -->
              <div class="px-1 flex flex-row justify-between">
                <div class="flex sm:justify-start justify-center my-1 scrollbar-wrapper">
                  <el-scrollbar class="my-1 mb-3" ref="scrollbar">
                    <div class="w-full flex flex-row gap-2" ref="itemList">
                      <!--<button @click="handleSelect(idQuestion)" v-for="(idQuestion,index) in idQuestions"
                            :key="idQuestion" v-bind:index="idQuestion"
                            :class="((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'false') ) ?
                             'bg-red-400 text-white  border-red-400 shadow-2xl '
                              : ((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'true')) ?
                               'bg-green-400 text-white  border-green-400 shadow-2xl ' 
                               : ((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'unanswered')) ?
                                'bg-gray-400 text-white  border-gray-400 shadow-2xl ' :
                                 (idQuestion.state == 'false' ) ?
                                 'bg-red-300 text-white  border-red-300' : (idQuestion.state == 'true') ?
                                  'bg-green-200 text-white  border-green-200' : (idQuestion.state == 'unanswered') ?
                                   'bg-gray-300 text-white  border-gray-300' : ''"
                            class="sm:p-2 p-1 rounded-lg border mx-1">

                      <span :class="dark ? 'text-white' :'text-gray-7000'" class="sm:text-base text-sm">Q{{
                          (index + 1)
                        }}</span>

                    </button>-->
                      <button class="question" v-for="(idQuestion, index) in idQuestions" :key="idQuestion"
                        v-bind:index="idQuestion" @click="handleSelect(idQuestion, index)">
                        <span class="top">
                          <span v-if="idQuestion.state == 'unanswered'" class="position"
                            :class="((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'unanswered')) ? 'selected' : ''">{{
                              (index + 1) }}</span>
                          <CheckIcon v-if="idQuestion.state == 'true'" />
                          <TimesIcon v-if="idQuestion.state == 'false'" />
                        </span>
                        <span class="bar" :class="((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'false')) ? 'incorrect'
                          : ((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'true')) ? 'correct' :
                            ((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'unanswered')) ? 'incorrect' :
                              (idQuestion.state == 'false') ? 'incorrect' :
                                (idQuestion.state == 'true') ? 'correct' :
                                  (idQuestion.state == 'unanswered') ? 'unanswered' : ''"></span>
                      </button>
                    </div>
                  </el-scrollbar>
                  <!--<el-scrollbar class="sm:hidden block max-w-xxs my-1">
                    <div class=" w-full flex flex-row">
                      <button @click="handleSelect(idQuestion)" v-for="(idQuestion, index) in idQuestions"
                        :key="idQuestion" v-bind:index="idQuestion"
                        :class="((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'false')) ? 'bg-red-400 text-white  border-red-400 shadow-2xl ' : ((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'true')) ? 'bg-green-400 text-white  border-green-400 shadow-2xl ' : ((selectedIdType.id == idQuestion.id) && (selectedIdType.type == idQuestion.type) && (idQuestion.state == 'unanswered')) ? 'bg-gray-400 text-white  border-gray-400 shadow-2xl ' : (idQuestion.state == 'false') ? 'bg-red-300 text-white  border-red-300' : (idQuestion.state == 'true') ? 'bg-green-200 text-white  border-green-200' : (idQuestion.state == 'unanswered') ? 'bg-gray-300 text-white  border-gray-300' : ''"
                        class="sm:p-2 p-1 rounded-lg border mx-1">
                        <span :class="dark ? 'text-white' : 'text-gray-7000'" class="sm:text-base text-sm">Q{{
                          (index + 1)
                        }}</span>
                      </button>
                    </div>
                  </el-scrollbar>-->
                </div>

                <div class="flex flex-row justify-center items-center gap-2">
                  <button @click="openPauseSession" class="light-btn">
                    <!--<el-icon :size="16" class="mx-1">
                      <video-pause />
                    </el-icon>-->
                    <PauseIcon />
                  </button>
                  <button @click="endSession" class="light-btn">
                    <!--<el-icon :size="16" class="mx-1">
                      <switch-button />
                    </el-icon>-->
                    <OffIcon />
                  </button>

                </div>

              </div>
            </div>
            <!--<div class="flex justify-between items-center mx-6 sm:mt-3 sm:mb-6 sm:hidden">
              <div class="sm:block item-center hidden"></div>
              <div class="flex">
                <p class="bg-red-400 p-2 mr-6 rounded-lg text-white sm:block hidden">Question
                  {{ clockQuestion }}</p>
                <p class="bg-red-400 sm:py-2 px-3 rounded-lg text-white">Série {{ clockSession }}</p>
              </div>
              <div class="flex justify-center items-center">
                <button @click="clearMouseSelect" class="flex justify-center">
                  <el-icon :size="24" :color="activeTextSelection ? '#F87171' : '#ccc'" class="m-2">
                    <edit />
                  </el-icon>
                </button>
                <button @click="reportQuestionSessionModal = true" class="flex justify-center">
                  <el-icon :size="23" color="#ccc" class="m-2">
                    <warning-filled />
                  </el-icon>
                </button>
                <button v-if="sessionIsPlaylist" @click="deleteQuestionFromPlaylistDialogVisible = true"
                  class="flex justify-center">
                  <el-icon :size="23" color="#ccc" class="mx-1">
                    <delete />
                  </el-icon>
                </button>
                <button v-else @click="openAddToPlaylist" class="flex justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 m-2" width="24" height="24"
                    viewBox="0 0 24 24">
                    <path fill="#ccc"
                      d="M13 10H3c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zm0-4H3c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zm5 8v-3c0-.55-.45-1-1-1s-1 .45-1 1v3h-3c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1h-3zM3 16h6c.55 0 1-.45 1-1s-.45-1-1-1H3c-.55 0-1 .45-1 1s.45 1 1 1z" />
                  </svg>
                </button>
              </div>
            </div>-->
            <div v-if="selectedIdType && selectedQuestion" class="w-full">
              <div v-if="selectedIdType.type != 'clinicalcas'"
                class="px-0 py-1 flex sm:flex-row flex-col justify-between items-center mb-4">
                <div class="flex flex-row items-center sm:w-auto w-full sm:justify-start justify-between">
                  <div class="flex items-center gap-3">
                    <span class="font-bold">Question :</span>
                    <div v-if="selectedQuestion.Verified === '1'" class="w-5 h-5 mr-2">
                      <w-tooltip left align-top>
                        <template #activator="{ on }">
                          <!--<svg v-on="on" version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"
                          viewBox="0 0 50.000000 50.000000">
                          <g transform="translate(0.000000,50.000000) scale(0.024631,-0.024631)" fill="#000000"
                            stroke="none">
                            <path fill="#f87171"
                              d="M914 1913 c-48 -54 -93 -104 -99 -110 -8 -10 -42 -3 -155 31 -80 25 -148 42 -151 38 -4 -4 -21 -70 -39 -148 l-33 -141 -149 -33 c-91 -20 -147 -38 -145 -44 3 -6 23 -74 46 -150 l42 -139 -63 -56 c-35 -31 -85 -77 -113 -103 l-50 -48 75 -67 c41 -38 92 -84 113 -103 l39 -36 -45 -144 c-32 -102 -42 -147 -34 -151 7 -4 73 -21 148 -38 l137 -32 22 -97 c36 -161 47 -197 56 -196 5 1 71 21 148 45 l138 43 37 -39 c20 -22 66 -74 104 -115 l67 -75 68 75 c37 41 83 93 103 115 l37 39 138 -43 c77 -24 143 -44 148 -45 9 -1 20 35 56 196 l22 97 137 32 c75 17 141 34 148 38 8 4 -2 49 -34 151 l-45 144 39 36 c21 19 72 65 113 103 l75 67 -50 48 c-27 26 -78 72 -113 103 l-63 56 42 139 c23 76 43 144 46 150 2 6 -55 24 -144 44 -81 18 -149 33 -150 34 -1 1 -16 64 -33 141 -18 77 -35 143 -39 147 -3 4 -71 -13 -151 -38 -113 -34 -147 -41 -155 -31 -86 98 -189 207 -195 207 -4 0 -47 -44 -96 -97z m646 -538 l65 -65 -375 -375 -375 -375 -195 195 -195 195 68 67 67 68 128 -128 127 -127 305 305 c168 168 307 305 310 305 3 0 35 -29 70 -65z" />
                          </g>
                        </svg>-->
                          <VerifiedBadgeIcon />
                        </template>
                        Ce signe signifie que la question a été vérifiée par notre équipe afin que la réponse ne
                        soit pas fausse.
                      </w-tooltip>
                    </div>
                    <span v-if="selectedQuestion.type == 'qcs'" class="badge">QCU</span>
                    <span v-if="selectedQuestion.type == 'qcm'" class="badge">QCM</span>
                    <span v-if="selectedQuestion.type == 'qroc'" class="badge">QROC</span>
                    <div class="flex-wrap sm:flex hidden gap-2">
                      <span v-for="item in tags" :key="item" class="badge m-1">{{ item }}
                      </span>
                    </div>

                    <div v-if="selectedQuestion.Verified === '0'" class="w-5 h-5">
                      <w-tooltip left align-top>
                        <template #activator="{ on }">
                          <svg v-on="on" version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"
                            viewBox="0 0 50.000000 50.000000">
                            <g transform="translate(0.000000,50.000000) scale(0.023364,-0.023364)" fill="#000000"
                              stroke="none">
                              <path fill="#f87171"
                                d="M872 2119 c-181 -32 -394 -142 -535 -278 -527 -506 -397 -1388 253 -1716 162 -81 291 -110 495 -109 175 0 253 14 397 72 344 138 598 459 653 825 6 38 4 48 -14 66 -12 12 -32 21 -46 21 -29 0 -65 -32 -65 -58 0 -10 -10 -56 -21 -103 -80 -319 -332 -578 -656 -675 -119 -35 -311 -44 -432 -20 -378 77 -670 370 -747 748 -22 109 -15 334 14 428 50 166 122 291 236 409 194 200 448 303 716 288 167 -9 323 -59 462 -148 l47 -30 -30 -41 c-16 -23 -28 -42 -26 -44 3 -4 227 32 231 37 6 6 -30 209 -38 217 -4 4 -21 -10 -37 -30 l-30 -36 -66 40 c-81 49 -196 99 -283 123 -76 20 -389 30 -478 14z" />
                              <path fill="#f87171"
                                d="M533 1607 c-232 -232 -263 -267 -263 -294 0 -38 30 -63 74 -63 26 0 67 37 293 262 232 231 263 266 263 294 0 39 -27 64 -71 64 -31 0 -55 -22 -296 -263z" />
                              <path fill="#f87171"
                                d="M651 1496 l-224 -224 79 -87 c123 -137 211 -213 256 -226 54 -14 108 -3 160 31 32 22 46 26 60 19 32 -17 33 -54 2 -94 -36 -47 -44 -126 -19 -176 19 -37 282 -309 300 -309 5 0 111 101 235 225 l225 225 -130 131 c-161 161 -194 183 -274 177 -44 -3 -68 -11 -94 -31 -41 -31 -50 -33 -71 -11 -22 22 -20 47 9 90 32 47 41 96 25 147 -9 32 -44 74 -158 189 -80 82 -148 148 -151 148 -4 0 -107 -101 -230 -224z m453 -159 c0 -21 -10 -47 -28 -70 -26 -33 -28 -43 -24 -97 3 -45 1 -60 -9 -60 -7 0 -13 -6 -13 -14 0 -12 -7 -13 -31 -6 -40 12 -87 3 -124 -25 -69 -50 -82 -44 -225 99 l-105 106 165 165 165 165 114 -115 c101 -100 115 -118 115 -148z m378 -344 l118 -118 -165 -165 -165 -165 -115 116 c-131 132 -135 144 -84 219 24 36 30 53 27 87 -2 34 0 43 15 47 23 6 12 28 -12 24 -9 -1 -26 7 -37 18 -20 20 -20 22 -4 32 13 8 22 6 39 -11 34 -34 126 -31 177 6 64 45 74 40 206 -90z" />
                              <path fill="#f87171"
                                d="M864 1473 c-11 -46 -40 -96 -74 -128 -37 -34 -69 -98 -70 -135 0 -18 15 -40 45 -70 39 -38 48 -43 63 -32 45 32 94 52 127 52 33 0 35 2 35 35 0 33 20 82 52 127 11 15 2 28 -67 98 -44 44 -85 80 -92 80 -7 0 -16 -12 -19 -27z" />
                              <path fill="#f87171"
                                d="M1234 878 c3 -18 10 -78 16 -132 6 -55 13 -102 16 -105 2 -3 58 49 124 114 66 65 118 120 117 122 -3 2 -251 33 -270 33 -4 0 -5 -15 -3 -32z" />
                              <path fill="#f87171"
                                d="M1513 638 c-145 -145 -266 -273 -269 -285 -12 -45 42 -92 90 -77 26 8 512 488 532 526 19 37 18 45 -11 73 -51 52 -57 47 -342 -237z" />
                            </g>
                          </svg>
                        </template>
                        Ce signe signifie que la question n'a pas encore été vérifiée par notre équipe en raison du
                        manque de temps et du grand nombre de questions et elle sera vérifiée dès que possible (la
                        réponse utilisée est le corrigé type de l'examen).
                      </w-tooltip>
                    </div>
                  </div>
                  <div class="sm:hidden flex justify-center gap-2">
                    <button v-if="sessionIsPlaylist" @click="deleteQuestionFromPlaylistDialogVisible = true"
                      class="flex justify-center">
                      <el-icon :size="18" color="#ccc" class="mx-1">
                        <delete />
                      </el-icon>
                    </button>
                    <button v-else @click="openAddToPlaylist" class="flex justify-center">
                      <AddPlaylistIcon />
                    </button>
                    <button @click="reportQuestionSessionModal = true" class="flex justify-center">
                      <ReportIcon />
                    </button>
                    <button @click="clearMouseSelect" class="flex justify-center">
                      <PenIcon />
                    </button>
                  </div>
                </div>
                <div class="flex flex-col items-center sm:w-auto w-full sm:mt-0 mt-4">
                  <div class="flex-wrap flex sm:hidden gap-2 mb-2">
                    <span v-for="item in tags" :key="item" class="badge">{{ item }}
                    </span>
                  </div>
                  <div class="flex gap-3 sm:flex-row flex-col sm:w-auto w-full">
                    <div class="timer-badge">
                      <span>
                        <ClockIcon />
                      </span>
                      <span>Question</span>
                      <span>{{ clockQuestion }}</span>
                    </div>
                    <div class="timer-badge">
                      <span>
                        <ClockIcon2 />
                      </span>
                      <span>Session</span>
                      <span>{{ clockSession }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="px-0 py-1 flex sm:flex-row flex-col justify-between items-center mb-4">
                <div class="flex flex-row items-center sm:w-auto w-full sm:justify-start justify-between">
                  <div class="flex items-center gap-3">
                    <span class="font-bold">Cas clinique :</span>
                    <div class="flex-wrap sm:flex hidden gap-2">
                      <span v-for="item in tags" :key="item" class="badge m-1">{{ item }}
                      </span>
                    </div>

                    <div v-if="selectedQuestion.Verified === '1'" class="w-5 h-5">
                      <w-tooltip left align-top>
                        <template #activator="{ on }">
                          <svg v-on="on" version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"
                            viewBox="0 0 50.000000 50.000000">
                            <g transform="translate(0.000000,50.000000) scale(0.024631,-0.024631)" fill="#000000"
                              stroke="none">
                              <path fill="#f87171"
                                d="M914 1913 c-48 -54 -93 -104 -99 -110 -8 -10 -42 -3 -155 31 -80 25 -148 42 -151 38 -4 -4 -21 -70 -39 -148 l-33 -141 -149 -33 c-91 -20 -147 -38 -145 -44 3 -6 23 -74 46 -150 l42 -139 -63 -56 c-35 -31 -85 -77 -113 -103 l-50 -48 75 -67 c41 -38 92 -84 113 -103 l39 -36 -45 -144 c-32 -102 -42 -147 -34 -151 7 -4 73 -21 148 -38 l137 -32 22 -97 c36 -161 47 -197 56 -196 5 1 71 21 148 45 l138 43 37 -39 c20 -22 66 -74 104 -115 l67 -75 68 75 c37 41 83 93 103 115 l37 39 138 -43 c77 -24 143 -44 148 -45 9 -1 20 35 56 196 l22 97 137 32 c75 17 141 34 148 38 8 4 -2 49 -34 151 l-45 144 39 36 c21 19 72 65 113 103 l75 67 -50 48 c-27 26 -78 72 -113 103 l-63 56 42 139 c23 76 43 144 46 150 2 6 -55 24 -144 44 -81 18 -149 33 -150 34 -1 1 -16 64 -33 141 -18 77 -35 143 -39 147 -3 4 -71 -13 -151 -38 -113 -34 -147 -41 -155 -31 -86 98 -189 207 -195 207 -4 0 -47 -44 -96 -97z m646 -538 l65 -65 -375 -375 -375 -375 -195 195 -195 195 68 67 67 68 128 -128 127 -127 305 305 c168 168 307 305 310 305 3 0 35 -29 70 -65z" />
                            </g>
                          </svg>
                        </template>
                        Ce signe signifie que la question a été vérifiée par notre équipe afin que la réponse ne
                        soit pas fausse.
                      </w-tooltip>
                    </div>

                    <div v-if="selectedQuestion.Verified === '0'" class="w-5 h-5">
                      <w-tooltip left align-top>
                        <template #activator="{ on }">
                          <svg v-on="on" version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"
                            viewBox="0 0 50.000000 50.000000">
                            <g transform="translate(0.000000,50.000000) scale(0.023364,-0.023364)" fill="#000000"
                              stroke="none">
                              <path fill="#f87171"
                                d="M872 2119 c-181 -32 -394 -142 -535 -278 -527 -506 -397 -1388 253 -1716 162 -81 291 -110 495 -109 175 0 253 14 397 72 344 138 598 459 653 825 6 38 4 48 -14 66 -12 12 -32 21 -46 21 -29 0 -65 -32 -65 -58 0 -10 -10 -56 -21 -103 -80 -319 -332 -578 -656 -675 -119 -35 -311 -44 -432 -20 -378 77 -670 370 -747 748 -22 109 -15 334 14 428 50 166 122 291 236 409 194 200 448 303 716 288 167 -9 323 -59 462 -148 l47 -30 -30 -41 c-16 -23 -28 -42 -26 -44 3 -4 227 32 231 37 6 6 -30 209 -38 217 -4 4 -21 -10 -37 -30 l-30 -36 -66 40 c-81 49 -196 99 -283 123 -76 20 -389 30 -478 14z" />
                              <path fill="#f87171"
                                d="M533 1607 c-232 -232 -263 -267 -263 -294 0 -38 30 -63 74 -63 26 0 67 37 293 262 232 231 263 266 263 294 0 39 -27 64 -71 64 -31 0 -55 -22 -296 -263z" />
                              <path fill="#f87171"
                                d="M651 1496 l-224 -224 79 -87 c123 -137 211 -213 256 -226 54 -14 108 -3 160 31 32 22 46 26 60 19 32 -17 33 -54 2 -94 -36 -47 -44 -126 -19 -176 19 -37 282 -309 300 -309 5 0 111 101 235 225 l225 225 -130 131 c-161 161 -194 183 -274 177 -44 -3 -68 -11 -94 -31 -41 -31 -50 -33 -71 -11 -22 22 -20 47 9 90 32 47 41 96 25 147 -9 32 -44 74 -158 189 -80 82 -148 148 -151 148 -4 0 -107 -101 -230 -224z m453 -159 c0 -21 -10 -47 -28 -70 -26 -33 -28 -43 -24 -97 3 -45 1 -60 -9 -60 -7 0 -13 -6 -13 -14 0 -12 -7 -13 -31 -6 -40 12 -87 3 -124 -25 -69 -50 -82 -44 -225 99 l-105 106 165 165 165 165 114 -115 c101 -100 115 -118 115 -148z m378 -344 l118 -118 -165 -165 -165 -165 -115 116 c-131 132 -135 144 -84 219 24 36 30 53 27 87 -2 34 0 43 15 47 23 6 12 28 -12 24 -9 -1 -26 7 -37 18 -20 20 -20 22 -4 32 13 8 22 6 39 -11 34 -34 126 -31 177 6 64 45 74 40 206 -90z" />
                              <path fill="#f87171"
                                d="M864 1473 c-11 -46 -40 -96 -74 -128 -37 -34 -69 -98 -70 -135 0 -18 15 -40 45 -70 39 -38 48 -43 63 -32 45 32 94 52 127 52 33 0 35 2 35 35 0 33 20 82 52 127 11 15 2 28 -67 98 -44 44 -85 80 -92 80 -7 0 -16 -12 -19 -27z" />
                              <path fill="#f87171"
                                d="M1234 878 c3 -18 10 -78 16 -132 6 -55 13 -102 16 -105 2 -3 58 49 124 114 66 65 118 120 117 122 -3 2 -251 33 -270 33 -4 0 -5 -15 -3 -32z" />
                              <path fill="#f87171"
                                d="M1513 638 c-145 -145 -266 -273 -269 -285 -12 -45 42 -92 90 -77 26 8 512 488 532 526 19 37 18 45 -11 73 -51 52 -57 47 -342 -237z" />
                            </g>
                          </svg>
                        </template>
                        Ce signe signifie que la question n'a pas encore été vérifiée par notre équipe en raison du
                        manque de temps et du grand nombre de questions et elle sera vérifiée dès que possible (la
                        réponse utilisée est le corrigé type de l'examen).
                      </w-tooltip>
                    </div>
                  </div>
                  <div class="sm:hidden flex justify-center gap-2">
                    <button v-if="sessionIsPlaylist" @click="deleteQuestionFromPlaylistDialogVisible = true"
                      class="flex justify-center">
                      <el-icon :size="18" color="#ccc" class="mx-1">
                        <delete />
                      </el-icon>
                    </button>
                    <button v-else @click="openAddToPlaylist" class="flex justify-center">
                      <AddPlaylistIcon />
                    </button>
                    <button @click="reportQuestionSessionModal = true" class="flex justify-center">
                      <ReportIcon />
                    </button>
                    <button @click="clearMouseSelect" class="flex justify-center">
                      <PenIcon />
                    </button>
                  </div>
                </div>
                <div class="flex flex-col items-center sm:w-auto w-full sm:mt-0 mt-4">
                  <div class="flex-wrap flex sm:hidden gap-2 mb-2">
                    <span v-for="item in tags" :key="item" class="badge">{{ item }}
                    </span>
                  </div>
                  <div class="flex gap-3 sm:flex-row flex-col sm:w-auto w-full">
                    <div class="timer-badge">
                      <span>
                        <ClockIcon />
                      </span>
                      <span>Question</span>
                      <span>{{ clockQuestion }}</span>
                    </div>
                    <div class="timer-badge">
                      <span>
                        <ClockIcon2 />
                      </span>
                      <span>Session</span>
                      <span>{{ clockSession }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="flex sm:flex-row flex-col items-center">

                  <div v-if="selectedQuestion.image != null">
                    <el-image class="rounded-lg sm:ml-2 mt-2" style="width: 100px; height: 100px"
                      :src="selectedQuestion.image.toString()" :preview-src-list="[selectedQuestion.image.toString()]">
                    </el-image>
                  </div>


                  <div class="p-2 br font-bold" v-if="activeTextSelection">
                    <WordHighlighter @mouseup="handleMouseSelect" :query=textHighlight>
                      {{ selectedQuestion.question }}
                    </WordHighlighter>
                  </div>
                  <div v-else class="p-2 font-bold select-none br">
                    {{ selectedQuestion.question }}
                  </div>


                </div>
                <div class="sm:flex hidden justify-center gap-2">
                  <button v-if="sessionIsPlaylist" @click="deleteQuestionFromPlaylistDialogVisible = true"
                    class="flex justify-center">
                    <el-icon :size="18" color="#ccc" class="mx-1">
                      <delete />
                    </el-icon>
                  </button>
                  <button v-else @click="openAddToPlaylist" class="flex justify-center">
                    <AddPlaylistIcon />
                  </button>
                  <button @click="reportQuestionSessionModal = true" class="flex justify-center">
                    <ReportIcon />
                  </button>
                  <button @click="clearMouseSelect" class="flex justify-center">
                    <PenIcon />
                  </button>
                </div>
              </div>

            </div>
          </div>



          <div class="flex flex-col w-full">
            <!-- <div class="inline-flex justify-between items-center">
                    <div class="mb-2">
                      <p :class="dark ? 'text-white':'text-gray-800'" class="font-semibold text-sm text-left ml-2 sm:mt-0 sm:mt-5 mt-2 sm:block hidden">Question en cours</p>
                    </div>
                    <div></div>
                </div> -->


            <div class="h-full">
              <div :class="isLOadingQuestions ? 'block' : 'hidden'"
                class="flex items-center justify-center sm:my-32 w-full">
                <!--<div class="fulfilling-square-spinner">
                  <div class="spinner-inner"></div>
                </div>-->
                <div class="loading">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>


              <div :class="isLOadingQuestions ? 'hidden' : 'flex flex-col justify-between h-full'">



                <div v-if="selectedIdType && selectedQuestion" class="w-full text-left flex flex-col h-full">

                  <!-- <div class="sm:mb-6 mb-2 flex flex-row">
                        <div class="sm:block hidden" v-for="item in tags" :key=item>
                          <el-tag class="sm:block hidden mx-1" type="danger">{{item}}</el-tag>
                        </div>
                      </div> -->



                  <div v-if="selectedQuestion.type == 'qroc'" class="max-w-sm sm:max-w-4xl w-full">
                    <div v-if="showResponse">
                      <div :class="dark ? 'bg-gray-600 text-white' : 'bg-gray-100 '"
                        class="p-2 rounded-lg select-none br">
                        {{ selectedQuestion.response }}
                      </div>
                      <div v-if="qrocResponse == null" class="flex justify-center mt-4">
                        <button @click="respondToQroc(true, selectedQuestion.id)"
                          class="flex items-center justify-center py-2 px-5 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-green-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                          <el-icon color="#fff" :size="15" class="mr-2">
                            <check />
                          </el-icon>
                          Correcte
                        </button>
                        <button @click="respondToQroc(false, selectedQuestion.id)"
                          class="flex items-center justify-center py-2 px-7 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                          <el-icon color="#fff" :size="15" class="mr-2">
                            <close />
                          </el-icon>
                          Fausse
                        </button>
                      </div>

                      <div v-if="qrocResponse != null" class="flex justify-center mt-4">
                        <p v-if="qrocResponse == true"
                          class="p-2 mx-1 bg-green-200 border border-green-500 text-green-500 rounded-lg">
                          <el-icon color="#10B981" :size="15" class="mr-2">
                            <check />
                          </el-icon>
                          Réponse correte
                        </p>
                        <p v-if="qrocResponse == false"
                          class="p-2 mx-1 bg-red-200 border border-red-500 text-red-500 rounded-lg">
                          <el-icon color="#EF4444" :size="15" class="mr-2">
                            <close />
                          </el-icon>
                          Réponse fausse
                        </p>
                      </div>

                    </div>
                  </div>


                  <div v-if="selectedQuestion.type == 'qcm' || selectedQuestion.type == 'qcs'"
                    class="w-full quiz-section__propositions">

                    <div class="w-full" v-for=" (proposition, key) in selectedQuestion.propositions"
                      :key="proposition.id" @click="selectSuppositions(proposition.id)"
                      v-shortkey="(this.reportQuestionSessionModal || this.addToPlaylistModal) ? [] : [getShortKeyLetter(key)]"
                      @shortkey="selectSuppositions(proposition.id)">
                      <div class="flex justify-between items-center quiz-section__propositions-item" :class="(showResponse && verifiedSelection(proposition.id) && proposition.state == '1') ? 'correct'
                        : (showResponse && verifiedSelection(proposition.id) && proposition.state == '0') ? 'incorrect'
                          : (showResponse && !verifiedSelection(proposition.id) && proposition.state == '1') ? 'correct' :
                            verifiedSelection(proposition.id) ? 'bg-gray-300' : ''">

                        <div class="select-none flex flex-row w-full items-center">
                          <div>
                            <span class="quiz-section__propositions-item-label"
                              :class="verifiedSelection(proposition.id) ? 'selected' : ''">{{
                                String.fromCharCode(key + 65)
                              }}</span>
                            &nbsp;
                          </div>
                          <div class="flex-1">
                            <span class="quiz-section__propositions-item-proposal">{{ proposition.proposal }}</span>
                          </div>
                          <div class="flex flex-row items-center">
                            <span class="mx-1"
                              :class="(showResponse && verifiedSelection(proposition.id) && proposition.state == '1') || (showResponse && !verifiedSelection(proposition.id) && proposition.state == '1') ? 'success-proposal-percentage' : 'danger-proposal-percentage'"
                              v-if="(showResponse)">
                              {{ Math.round(proposition.one / (proposition.one + proposition.zero) * 100) }} %
                            </span>
                            <el-popover placement="top" :width="150" trigger="hover"
                              popper-style="background-color: coral;">
                              <template #reference>
                                <div>
                                  <div
                                    v-if="(showResponse && verifiedSelection(proposition.id) && proposition.state == '1')">

                                    <!--<el-icon color="#34D399" :size="15" class="mr-2 px-2">
                                        <CircleCheckFilled />
                                      </el-icon>-->
                                    <CircleCheckIcon />

                                  </div>
                                  <div
                                    v-if="(showResponse && verifiedSelection(proposition.id) && proposition.state == '0')">

                                    <!--<el-icon color="#F87171" :size="15" class="mr-2 px-2">
                                        <CircleCloseFilled />
                                      </el-icon>-->

                                    <CircleTimesIcon />

                                  </div>
                                </div>
                              </template>
                              <div class="demo-progress ">
                                <el-progress class="mb-2" :text-inside="false" :stroke-width="18"
                                  :percentage="Math.round(proposition.one / (proposition.one + proposition.zero) * 100)"
                                  color="#34D399" />
                                <el-progress :text-inside="false" :stroke-width="18"
                                  :percentage="Math.round(proposition.zero / (proposition.one + proposition.zero) * 100)"
                                  color="#F87171" />
                              </div>
                            </el-popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="selectedIdType.type == 'clinicalcas'" class="w-full mb-5">
                    <ClinicalCaseForSessionTest :dark="dark" @selected_answer="setClinicalCaseSelected"
                      :selectedQuestion="selectedQuestion" :OldselectedAnswer="selectedAnswer"
                      :OldqrocResponse="OldqrocResponse" :show="showQrocResponse" :showResponse="showResponse" />
                  </div>

                  <div class="w-full quiz-section__explanations"
                    v-if="showResponse">
                    <div>
                      <div>
                        <span class="font-bold">Explication :</span>
                      </div>
                      <div class=" flex sm:flex-row flex-col items-center">
                        <div v-if="selectedQuestion.explanation_image != null">
                          <el-image class="rounded-lg sm:ml-2 mt-2" style="width: 100px; height: 100px"
                            :src="selectedQuestion.explanation_image.toString()"
                            :preview-src-list="[selectedQuestion.explanation_image.toString()]">
                          </el-image>
                        </div>

                        <div class="p-2 select-none br" v-if="selectedQuestion.explanation != null">
                          <!-- {{selectedQuestion.explanation}} -->
                          <span v-html="selectedQuestion.explanation"></span>
                        </div>

                      </div>
                    </div>
                    <!-- <div :class="dark ? 'border-gray-600 bg-gray-600 text-white' :'bg-white text-gray-700'"
                      class="border rounded-lg" v-if="selectedQuestion.type== 'qcm' || selectedQuestion.type== 'qcs'">
                      <div class="border-b px-2 py-1">
                        Explication
                      </div>
                      <div class="p-2 select-none br">
                        {{selectedQuestion.explanation}}
                      </div>
                    </div> -->
                  </div>


                  <div class="mt-4 flex justify-end w-full">
                    <div class="flex items-center gap-4 sm:w-1/2 w-full"
                      v-show="showResponse && (indexSelected + 1 !== idQuestions.length)">
                      <button @click="nextQuestion(idQuestions, indexSelected)"
                        class="primary-btn flex-1 py-5 font-bold flex items-center justify-center gap-3">
                        <span>Question suivante</span>
                        <span>
                          <NextIcon />
                        </span>
                      </button>
                    </div>
                    <div class="flex items-center gap-4 sm:w-1/2 w-full" v-show="!showResponse">
                      <button @click="showResultOfQuestion(id)"
                        class="primary-btn flex-1 py-5 font-bold flex items-center justify-center">
                        Voir la réponse
                      </button>
                    </div>
                    <div class="flex items-center gap-4 sm:w-1/2 w-full"
                      v-show="showResponse && (indexSelected + 1 == idQuestions.length)">
                      <button @click="endSessionButton"
                        class="primary-btn flex-1 py-5 font-bold flex items-center justify-center">
                        Quitter la série
                      </button>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
        <!--<div class="fulfilling-square-spinner">
          <div class="spinner-inner"></div>
        </div>-->
        <div class="loading">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import {
  Check,
  Close,
  Delete
} from '@element-plus/icons';
import ClinicalCaseForSessionTest from './ClinicalCaseForSessionTest.vue'
import WordHighlighter from "vue-word-highlighter";
import { onBeforeUnmount, getCurrentInstance } from 'vue'
import store from "../../store.js";
import ExitConfirmationSession from './SessionModals/ExitConfirmationSession.vue'
import PauseSession from './SessionModals/PauseSession.vue'
import AddToPlayList from './PlaylistModals/AddToPlayList.vue'
import ReportQuestionSession from './SessionModals/ReportQuestionSession.vue'
import DeleteQuestionFromPlaylist from './PlaylistModals/DeleteQuestionFromPlaylist.vue'
import { ElMessage } from 'element-plus';
import TimesIcon from '../icons/TimesIcon.vue';
import CheckIcon from '../icons/CheckIcon.vue';
import CircleCheckIcon from '../icons/CircleCheckIcon.vue';
import CircleTimesIcon from '../icons/CircleTimesIcon.vue';
import PauseIcon from '../icons/PauseIcon.vue';
import OffIcon from '../icons/OffIcon.vue';
import VerifiedBadgeIcon from '../icons/VerifiedBadgeIcon.vue';
import ClockIcon from '../icons/ClockIcon.vue';
import ClockIcon2 from '../icons/ClockIcon2.vue';
import PenIcon from '../icons/PenIcon.vue';
import AddPlaylistIcon from '../icons/AddPlaylistIcon.vue';
import ReportIcon from '../icons/ReportIcon.vue';
import NextIcon from '../icons/NextIcon.vue';
import axiosIns from '../../plugins/axios';

export default {
  components: {
    Check,
    Close,
    Delete,
    ClinicalCaseForSessionTest,
    ExitConfirmationSession,
    PauseSession,
    ReportQuestionSession,
    DeleteQuestionFromPlaylist,
    AddToPlayList,
    WordHighlighter,
    TimesIcon,
    CheckIcon,
    CircleCheckIcon,
    CircleTimesIcon,
    PauseIcon,
    OffIcon,
    VerifiedBadgeIcon,
    ClockIcon,
    ClockIcon2,
    PenIcon,
    AddPlaylistIcon,
    ReportIcon,
    NextIcon
  },
  props: {
    dark: Boolean,
    SessionLogout: Boolean,
    linkPath: Function,
  },
  data() {
    return {
      isUpdated: false,
      // pour le temps de la session
      times: [],
      animateFrameS: 0,
      animateFrameQ: 0,
      nowTime: 0,
      diffTimeSession: 0,
      diffTimeQuestion: 0,
      startTimeS: 0,
      startTimeQ: 0,
      isRunning: false,

      pushToPlaylistList: false,

      sessionIsExam: null,
      sessionIsPlaylist: null,

      isEndSession: false,
      NoteSession: null,

      tags: [],

      isLoading: false,
      isLOadingQuestions: false,
      idQuestions: [],
      selectedIdType: null,
      selectedQuestion: null,

      // pour l'index de l'élement sélectionné
      indexSelected: 0,
      previousIndexSelected: 0,
      showResponse: false,

      // pour les réponse sélectionnées à partir d'un qcm/qcu
      selectedAnswer: [],
      OldqrocResponse: [],
      showQrocResponse: [],
      // pour les réponses d'un qroc
      qrocResponse: null,

      idSession: null,

      //pour le modal
      showExitConfirmation: false,
      pauseSessionModal: false,
      reportQuestionSessionModal: false,
      exitConfirmation: null,
      nextroute: null,

      activeTextSelection: false,
      textHighlight: null,

      addToPlaylistModal: false,
      deleteQuestionFromPlaylistDialogVisible: false
    }
  },

  mounted() {
    //window.scrollTo(0, 200);
    this.isLoading = true;
    this.idSession = this.$route.query.id;
    axiosIns
      .get("/session/" + this.idSession)
      .then(({ data }) => {

        this.linkPath(data.name);
        let questions = data.result;
        questions.sort((a, b) => b.year - a.year);
        this.idQuestions = questions;
        this.diffTimeQuestion = this.idQuestions[this.indexSelected]['time'];
        this.handleSelect(this.idQuestions[this.indexSelected]);
        this.diffTimeSession = data.time;
        this.sessionIsExam = data.isExam;
        this.sessionIsPlaylist = data.isPlaylist

        this.isLoading = false;
      })
      .catch(() => {
        this.errorm();
        this.isLoading = false;
      });
  },

  methods: {
    handleItemClick(index) {
      const itemListEl = this.$refs.itemList;
      const nextItemIndex = index + 1;

      if (nextItemIndex < this.idQuestions.length) {
        const nextItemEl = itemListEl.children[nextItemIndex];
        this.scrollToItem(nextItemEl);
      }
    },
    scrollToItem(itemEl) {
      const scrollbarEl = this.$refs.scrollbar.$el;
      const scrollContainer = scrollbarEl.querySelector('.el-scrollbar__wrap');

      const itemRect = itemEl.getBoundingClientRect();
      const containerRect = scrollContainer.getBoundingClientRect();
      const gap = 8;

      if (itemRect.right > containerRect.right) {
        const offset = scrollContainer.scrollLeft + (itemRect.width * 2);
        scrollContainer.scrollTo({
          left: offset,
          behavior: 'smooth',
        });
      } else if((itemRect.left - containerRect.left) <  (itemRect.width * 2) + gap) {
        const offset = scrollContainer.scrollLeft - (itemRect.width * 2);
        scrollContainer.scrollTo({
          left: offset,
          behavior: 'smooth',
        });
      }
      
    },
    getShortKeyLetter(key) {
      return String.fromCharCode(key + 97);
    },
    shortKeyAction(event) {

      switch (event.srcKey) {
        case 'pause':
          this.togglePause();
          break;
        case 'response':
          this.showResultOfQuestion()
          break;
        case 'enter':
          this.showResultOfQuestion()
          break;
        case 'next':
          this.nextQuestion(this.idQuestions, this.indexSelected);
          break;
        case 'previous':
          this.previousQuestion(this.idQuestions, this.indexSelected);
          break;
        case 'fullscreen':
          // alert("fullscreen");
          this.toggleFullscreen();
          break;
      }
    },

    toggleFullscreen() {
      const elem = this.$refs.fullscreenElement;

      if (!document.fullscreenElement) {
        // Request fullscreen
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
    previousQuestion(array, currentIndex) {
      this.selectedAnswer = [];
      this.qrocResponse = null;
      this.showResponse = false;
      if ((currentIndex - 1) >= 0) {
        currentIndex = currentIndex - 1;
        this.handleSelect(array[currentIndex]);
      }
    },
    togglePause() {
      if (this.pauseSessionModal == false) {
        this.openPauseSession();
      } else {
        this.closePauseSession();
      }
    },
    handleMouseSelect() {
      this.textHighlight = window.getSelection().toString();
    },

    clearMouseSelect() {
      this.textHighlight = null;
      this.activeTextSelection = !this.activeTextSelection
    },

    reload() {
      this.indexSelected = this.indexSelected - 1
      axiosIns
        .get("/session/" + this.idSession)
        .then(({ data }) => {
          this.idQuestions = data.result;
          this.diffTimeQuestion = this.idQuestions[this.indexSelected]['time'];
          this.handleSelect(this.idQuestions[this.indexSelected]);
          this.diffTimeSession = data.time;
          this.sessionIsExam = data.isExam;
          this.sessionIsPlaylist = data.isPlaylist

          this.isLoading = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });

    },
    //Les fonctions du modal
    closeExitConfirmationSession() {
      this.isEndSession = false
      this.exitConfirmation = false;
      this.showExitConfirmation = false;
      this.startTimerSession();
      this.startTimerQuestion();
    },

    confirmeExitConfirmationSession() {
      this.isEndSession = false
      this.exitConfirmation = true;
      this.showExitConfirmation = false;
      this.nextroute();
    },

    openPauseSession() {
      this.pauseSessionModal = true;
      this.stopTimer();
    },
    closePauseSession() {
      this.pauseSessionModal = false;
      if (!this.showResponse) {
        this.startTimerSession();
        this.startTimerQuestion();
      }

    },

    closeReportQuestion() {
      this.reportQuestionSessionModal = false;
    },

    openAddToPlaylist() {
      this.addToPlaylistModal = true;
    },

    closeAddToPlaylist() {
      this.addToPlaylistModal = false;
    },


    closeDeleteQuestionFromPlaylistModal() {
      this.deleteQuestionFromPlaylistDialogVisible = false
    },

    closeDeleteQuestionFromPlaylistModalWithdelete() {
      this.idQuestions = this.idQuestions.filter(tab => {
        return (tab !== this.selectedIdType);
      });

      if (this.idQuestions.length == 0) {
        this.$router.push("/dashboard/playlistsList");
        axiosIns.delete("/session/" + this.idSession)
          .then(() => {
          })
          .catch(() => {
          });
        this.pushToPlaylistList = true;
        this.deleteQuestionFromPlaylistDialogVisible = false;
      } else {
        if (this.idQuestions.length == this.indexSelected) {

          this.reload();
          this.deleteQuestionFromPlaylistDialogVisible = false;

        } else {
          this.handleSelect(this.idQuestions[this.indexSelected]);
          this.deleteQuestionFromPlaylistDialogVisible = false;
        }
      }

    },

    // la gestion du temps de la session ******************************************
    setSubtractStartTimeS(time) {
      var timeS = (typeof time) !== 'undefined' ? time : 0;
      this.startTimeS = Math.floor(performance.now() - timeS);
    },

    setSubtractStartTimeQ(time) {
      var timeQ = typeof time !== 'undefined' ? time : 0;
      this.startTimeQ = Math.floor(performance.now() - timeQ);
    },

    startTimerSession() {
      var vm = this;
      vm.setSubtractStartTimeS(vm.diffTimeSession);

      (function loop() {
        vm.nowTime = Math.floor(performance.now());
        vm.diffTimeSession = vm.nowTime - vm.startTimeS;
        vm.animateFrameS = requestAnimationFrame(loop);
      }());
      vm.isRunning = true;
    },

    startTimerQuestion() {
      var vm = this;
      vm.setSubtractStartTimeQ(vm.diffTimeQuestion);

      (function loop() {
        vm.nowTime = Math.floor(performance.now());
        vm.diffTimeQuestion = vm.nowTime - vm.startTimeQ;
        vm.animateFrameQ = requestAnimationFrame(loop);
      }());
      vm.isRunning = true;
    },

    stopTimer() {
      this.isRunning = false;
      cancelAnimationFrame(this.animateFrameS);
      cancelAnimationFrame(this.animateFrameQ);
    },

    zeroPrefix(num, digit) {
      var zero = '';
      for (var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
    // fin de la gestion du temps de la session ***********************************


    // les données de la question sélectionnée
    handleSelect(key, idx = null) {
      this.previousIndexSelected = this.indexSelected
      this.textHighlight = null;
      this.activeTextSelection = false;
      this.showResponse = false;
      this.selectedAnswer = [];
      this.qrocResponse = null;
      this.stopTimer();
      this.idQuestions[this.indexSelected]['time'] = this.diffTimeQuestion;
      let tmptype = 2
      if (this.idQuestions[this.previousIndexSelected].type == 'question') {
        tmptype = 1
      }

      axiosIns
        .get("/getstateofquestion/" + this.idQuestions[this.previousIndexSelected].id + "/" + this.idSession + "/" + tmptype)
        .then(({ data }) => {
          this.idQuestions[this.previousIndexSelected].state = data.state;
          this.handleItemClick(idx)

        })
        .catch(() => {

        });
      this.selectedIdType = key;
      this.indexSelected = this.getIndexOfCurrentElement(this.idQuestions, key);
      this.diffTimeQuestion = this.idQuestions[this.indexSelected]['time'];
      this.isLOadingQuestions = true;
      if (key.type == 'question') {
        axiosIns
          .get("/getquestionofsession/" + key.id + "/" + this.idSession)
          .then(({ data }) => {
            this.tags = JSON.parse(data.result.tags)
            this.selectedQuestion = data.result;
            if (this.selectedQuestion.type == 'qroc') {
              if (data.state === 'unanswered') {
                this.startTimerSession();
                this.startTimerQuestion();
                this.qrocResponse = null;
              } else if (data.state === 'true') {
                this.diffTimeQuestion = this.idQuestions[this.indexSelected]['time'];
                this.stopTimer();
                this.showResponse = true;
                this.qrocResponse = true;
              } else {
                this.stopTimer();
                this.showResponse = true;
                this.qrocResponse = false;
              }
            } else if (this.selectedQuestion.type == 'qcs' || this.selectedQuestion.type == 'qcm') {
              if (data.state.generalstate !== 'unanswered') {
                this.selectedAnswer = data.state.answers;
                this.showResponse = true;
                this.stopTimer();
              } else {
                this.startTimerSession();
                this.startTimerQuestion();
              }
            }

            this.isLOadingQuestions = false;
          })
          .catch(() => {
            this.errorm();
            this.isLOadingQuestions = false;
          });

      } else {

        axiosIns
          .get("/getclinicalcaseofsession/" + key.id + "/" + this.idSession)
          .then(({ data }) => {
            this.selectedQuestion = data.clinicalcases;
            // il faut récupérer clinical case state

            this.tags = JSON.parse(data.clinicalcases.tags)
            var respond = false;
            let i = 0;
            data.result.forEach(element => {
              if (element.generalstate !== 'unanswered') {
                this.selectedAnswer = data.result;
                this.showResponse = true;
                respond = true;

              }
              if (element.questionType == 'qroc') {
                if (element.generalstate === 'unanswered') {
                  this.OldqrocResponse[i] = null;
                } else {
                  this.OldqrocResponse[i] = (element.generalstate === 'true');
                  this.showQrocResponse[i] = true;
                }
              }
              i = i + 1;
            })

            if (respond == true) {
              this.stopTimer()
            } else {
              this.startTimerSession();
              this.startTimerQuestion();
            }
            this.isLOadingQuestions = false;
          })
          .catch(() => {
            this.errorm();
            this.isLOadingQuestions = false;
          });
      }
    },

    getIndexOfCurrentElement(array, element) {
      for (var i = 0; i < array.length; i++) {
        if ((array[i].id == element.id) && (array[i].type == element.type)) {
          return i;
        }
      }
    },

    selectSuppositions(index) {
      if (!this.showResponse) {
        if (!this.verifiedSelection(index)) {
          this.selectedAnswer = [...this.selectedAnswer, index];
        } else {
          this.selectedAnswer = this.selectedAnswer.filter((element) => element !== index);
        }
      }
    },

    verifiedSelection(index) {
      var verified = false;
      this.selectedAnswer.forEach(element => {
        if (element == index) {
          verified = true;
        }
      });
      return verified;
    },


    showResultOfQuestion() {
      this.showResponse = true;
      if (this.selectedQuestion.type === "qcm" || this.selectedQuestion.type === "qcs") {
        this.stopTimer();
        axiosIns
          .put("/storeresponse", {
            sessionId: this.idSession,
            questionId: this.selectedQuestion.id,
            type: this.selectedQuestion.type,
            selectedQuestions: this.selectedAnswer,
            time: this.diffTimeQuestion
          })
          .then(() => {
            this.isLOadingQuestions = false;
          })
          .catch(() => {
            this.errorm();
            this.isLOadingQuestions = false;
          });
      } else if (this.selectedQuestion.type !== "qroc") {

        this.stopTimer();
        axiosIns
          .put("/storeresponse", {
            sessionId: this.idSession,
            selectedAnswer: this.selectedAnswer,
            state: 'true',
            type: 'clinicalcas',
            time: this.diffTimeQuestion
          })
          .then(() => {
            this.isLOadingQuestions = false;
          })
          .catch(() => {
            this.errorm();
            this.isLOadingQuestions = false;
          });
      }

    },


    respondToQroc(response, id) {
      this.stopTimer();
      this.isLOadingQuestions = true
      this.qrocResponse = response;
      axiosIns
        .put("/storeresponse", {
          sessionId: this.idSession,
          questionId: id,
          state: response.toString(),
          type: this.selectedQuestion.type,
          time: this.diffTimeQuestion
        })
        .then(() => {
          this.isLOadingQuestions = false;
        })
        .catch(() => {
          this.errorm();
          this.isLOadingQuestions = false;
        });
    },

    setClinicalCaseSelected(selectedAnswer) {
      this.selectedAnswer = selectedAnswer;
    },


    nextQuestion(array, currentIndex) {
      this.selectedAnswer = [];
      this.qrocResponse = null;
      this.showResponse = false;
      if ((currentIndex + 1) < array.length) {
        currentIndex = currentIndex + 1;
        this.handleSelect(array[currentIndex], currentIndex);
      }
    },


    endSession() {
      if (this.sessionIsPlaylist === 0 || this.sessionIsPlaylist === false) {
        if (this.sessionIsExam === '0') {
          this.$router.push("/dashboard/sessionsList");
        } else if (this.sessionIsExam === '1') {
          this.$router.push("/dashboard/examensList");
        }
      } else {
        this.$router.push("/dashboard/playlistsList");
      }
    },

    endSessionButton() {
      if (this.sessionIsPlaylist === 0 || this.sessionIsPlaylist === false) {
        if (this.sessionIsExam === '0') {
          this.$router.push("/dashboard/sessionsList");
        } else if (this.sessionIsExam === '1') {
          this.$router.push("/dashboard/examensList");
        }
      } else {
        this.$router.push("/dashboard/playlistsList");
      }

      this.isEndSession = true;

      axiosIns
        .get("/getnotesession/" + this.idSession)
        .then(({ data }) => {

          this.NoteSession = {
            labels: ['Correctes', 'Fausses', 'Non répondues'],
            datasets: [
              {
                data: [data.true, data.false, data.unanswered],
                backgroundColor: [
                  "#34D399",
                  "#F87171",
                  "#D1D5DB"
                ],
                hoverBackgroundColor: [
                  "#34D399",
                  "#F87171",
                  "#D1D5DB"
                ]
              }
            ]
          }
        })
        .catch(() => {
          this.errorm();
        });
    }

  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },

    shortKeys() {

      if (this.reportQuestionSessionModal || this.addToPlaylistModal) {
        return {};
      }
      return {
        pause: ['p'],
        fullscreen: ['m'],
        next: ['arrowright'],
        previous: ['arrowleft'],
        response: ['v'],
        enter: ['enter']

      }

    },

    // pour la gestion de temps de la session
    hoursSession: function () {
      return (Math.floor(this.diffTimeSession / 1000 / 60 / 60)).toString();
    },
    hoursQuestion: function () {
      return (Math.floor(this.diffTimeQuestion / 1000 / 60 / 60)).toString();
    },
    minutesSession: function () {
      return (Math.floor(this.diffTimeSession / 1000 / 60) % 60).toString();
    },
    minutesQuestion: function () {
      return (Math.floor(this.diffTimeQuestion / 1000 / 60) % 60).toString();
    },
    secondsSession: function () {
      return (Math.floor(this.diffTimeSession / 1000) % 60).toString();
    },
    secondsQuestion: function () {
      return (Math.floor(this.diffTimeQuestion / 1000) % 60).toString();
    },
    clockSession: function () {
      return this.zeroPrefix(this.hoursSession, 2) + ":" + this.zeroPrefix(this.minutesSession, 2) + ":" + this.zeroPrefix(this.secondsSession, 2)
    },
    clockQuestion: function () {
      return this.zeroPrefix(this.hoursQuestion, 2) + ":" + this.zeroPrefix(this.minutesQuestion, 2) + ":" + this.zeroPrefix(this.secondsQuestion, 2)
    }

  }
  ,

  beforeRouteLeave(to, from, next) {

    if (this.SessionLogout && to.path !== '/login') {
      this.stopTimer();
      this.idQuestions[this.indexSelected]['time'] = this.diffTimeQuestion
      this.isLoading = true;
      axiosIns.put("/session/" + this.idSession, {
        time: this.diffTimeSession
      })
        .then(() => {
          this.isUpdated = true;
        })
        .catch(() => {
        });

      axiosIns.put("/updateTime/" + this.idSession, {
        questionsTime: this.idQuestions
      })
        .then(() => {
          this.isUpdated = true;
          this.$store.dispatch("logoutAction").then(() => {
            next(false)
            this.$router.push("/login");
            this.$store.commit('set_user', null);
          });
        })
        .catch(() => {
        });


    } else if ((to.path === '/login') || (this.pushToPlaylistList)) {
      this.isLoading = false;
      this.pushToPlaylistList = false;
      next();
    } else {
      this.showExitConfirmation = true;
      this.stopTimer();
      this.nextroute = next;
    }
  }
  ,


  setup() {

    onBeforeUnmount(() => {
      const app = getCurrentInstance()
      if (!app.data.isUpdated) {

        app.data.idQuestions[app.data.indexSelected]['time'] = app.data.diffTimeQuestion


        axiosIns.put("/session/" + app.data.idSession, {
          time: app.data.diffTimeSession
        })
          .then(() => {

          })
          .catch(() => {

          });

        axiosIns.put("/updateTime/" + app.data.idSession, {
          questionsTime: app.data.idQuestions
        })
          .then(() => {

          })
          .catch(() => {

          });


      }
    })


    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return { errorm }

  }
  ,


}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.max-w-xxs {
  max-width: 14rem;
}

.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.style-yasser {
  font-family: "Montserrat", Helvetica, Arial, serif;
}

.demo-progress .el-progress--line {
  width: 135px;
}
</style>